/* Add styles for the heatmap in the modal */
.heatmap-modal .ant-modal-content {
  border-radius: 8px;
  overflow: hidden;
}

.heatmap-modal .ant-modal-body {
  padding: 0;
}

.heatmap-modal .heatmap-app {
  height: 100%;
  width: 100%;
  overflow: auto;
}

/* Existing styles - scoped to heatmap */
.heatmap-container {
  position: relative;
  overflow: visible;
}

.heatmap-container .cell {
  transition: all 0.2s ease-in-out;
}

.heatmap-container .cell:hover {
  transform: scale(1.05);
  z-index: 10;
}

/* Scope all generic classes to heatmap-app */
.heatmap-app {
  text-align: center;
}

.heatmap-app .app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .heatmap-app .app-logo {
    animation: heatmap-app-logo-spin infinite 20s linear;
  }
}

.heatmap-app .app-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.heatmap-app .app-link {
  color: #61dafb;
}

@keyframes heatmap-app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.report-form-section {
  margin-top: 2rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Form layout styles */
.report-form-section .ant-form-item-control-input {
  display: flex;
  justify-content: flex-start !important;
}

.report-form-section .ant-form-item-control-input-content {
  display: flex;
  flex-direction: column !important;
  gap: 8px;
  justify-content: flex-start !important;
  width: 100%;
}

/* Form field styles matching reportSetupTab */
.report-form-section .ant-select {
  height: 43.6px;
  border-radius: 0.75rem;
  width: 100%;
  max-width: 512px;
}

.report-form-section .ant-select-selector {
  border-color: rgb(170 171 172) !important;
  border-radius: 0.75rem;
  text-align: left !important;
}

.report-form-section .ant-select-selection-item {
  text-align: left !important;
  display: flex !important;
  align-items: center !important;
}

.report-form-section .ant-picker {
  height: 43.6px;
  border-radius: 0.75rem;
  border-color: rgb(170 171 172) !important;
  width: 100%;
  max-width: 512px;
}

.report-form-section .ant-input {
  text-align: left !important;
  width: 100%;
  max-width: 512px;
}

.report-form-section .ant-collapse-content .ant-form-item-control-input-content .ant-input {
  height: 43.6px;
  border-radius: 0.75rem;
  border-color: rgb(170 171 172) !important;
}

/* Helper text styles */
.report-form-section .ant-form-item-extra {
  text-align: left !important;
  color: #828385;
  font-size: 14px;
  margin-top: 4px;
}

/* Filter field styles */
.report-form-section .filters-list {
  margin-top: 8px;
}

.report-form-section .filters-list .ant-form-item-control-input-content {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 8px;
}

.report-form-section .filters-list .ant-select {
  width: 200px;
}

.report-form-section .ant-form > .ant-form-item {
  margin-bottom: 0px;
  border-bottom: 1px solid rgb(230,230,231);
  padding: 20px 0px 20px 0px;
}

.report-form-section .ant-form-item-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.report-form-section .ant-form-item-label {
  min-width: 230px;
  text-align: left;
}

.report-form-section .ant-form-item-control {
  max-width: 512px;
  width: 100%;
}

.report-form-section .ant-collapse {
  margin-top: 10px;
}

.report-form-section .selectedGroupByColumns-option .ant-select-item-option-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-form-section .ant-select-selection-item-content > span {
  display: none;
}

.report-form-section .numeric-filter-input {
  display: flex;
  align-items: center;
}

.report-form-section .numeric-filter-input .ant-input-number-input {
  display: flex;
  align-items: center;
  text-align: left;
  height: 30px;
  padding-left: 11px;
}

.report-form-section .numeric-filter-input .ant-input-number-input-placeholder {
  display: flex;
  align-items: center;
  height: 100%;
  text-align: left;
}

/* Submit button container styles */
.submit-button-container {
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0;
}

.submit-button-container .ant-btn {
  min-width: 120px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: 600 !important;
  line-height: 140% !important;
  font-size: 14px !important;
  height: 44px !important;
  padding: 8px !important;
  border-radius: 0.75rem !important;
  width: 144px !important;
  background-color: #445082 !important;
  color: #ffffff !important;
  transition: background-color 300ms ease-in-out !important;
}

.submit-button-container .ant-btn:hover {
  background-color: #2b3c6b !important;
}

.submit-button-container .ant-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.submit-button-container .ant-btn svg {
  margin-right: 8px;
}

/* Add field button alignment */
.report-form-section .ant-form-item .ant-btn-dashed {
  text-align: left;
  justify-content: flex-start;
}

/* Ensure dropdown options are also left-aligned */
.ant-select-dropdown .ant-select-item {
  text-align: left !important;
}

/* Ensure date picker input is left-aligned */
.report-form-section .ant-picker-input > input {
  text-align: left !important;
}

/* Date range styles */
.report-form-section .ant-picker-range {
  margin-top: 8px;
}
