.reportSetupTab .ant-select {
    /* width: 512px; */
    height: 43.6px;
    border-radius: 0.75rem;
}
.reportSetupTab .ant-select-selector{
    border-color: rgb(170 171 172) !important;
    border-radius: 0.75rem;
}
.reportSetupTab  .ant-picker {
    /* width: 512px; */
    height: 43.6px;
    border-radius: 0.75rem;

    border-color: rgb(170 171 172) !important;
    border-radius: 0.75rem;
}

.reportSetupTab .ant-collapse-content .ant-form-item-control-input-content .ant-input {
    /* width: 512px; */
    height: 43.6px;
    border-radius: 0.75rem;

    border-color: rgb(170 171 172) !important;
    border-radius: 0.75rem;
}

.filters-list .ant-form-item-control-input-content{
    display: flex;
    gap: 4px;
}

.reportSetupTab .ant-form > .ant-form-item {
    margin-bottom: 0px;
    border-bottom: 1px solid rgb(230,230,231);
    padding: 20px 0px 20px 0px;
}

.reportSetupTab .ant-collapse{
    margin-top: 10px;
}

.selectedGroupByColumns-option .ant-select-item-option-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ant-select-selection-item-content > span{
    display: none;
}
/* .reportSetupTab > .ant-form > .ant-form-item > .ant-row > .ant-form-item-label {
    min-width: 230px;
} */

.numeric-filter-input {
    display: flex;
    align-items: center;
  }
  
  .numeric-filter-input .ant-input-number-input {
    display: flex;
    align-items: center;
    text-align: left;
    height: 30px;
    padding-left: 11px;
  }
  
  .numeric-filter-input .ant-input-number-input-placeholder {
    display: flex;
    align-items: center;
    height: 100%;
    text-align: left;
  }